<template>
	<div>logout</div>
</template>

<script>
export default {
	name: "Logout",
	mounted: function () {
		if (localStorage.getItem("token")) {
			localStorage.removeItem("token");
			this.$router.push("/login");
		}
	}
}
</script>
